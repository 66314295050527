table {
    display: table;
    border-color: gray;
    text-indent: initial;
}

.paymentClass {
    padding: 20px;
}

.PaymentBalance {
    width: 306px
}

.paymentValue {
    padding-left: 119px;
}

.billIcon {
    font-size: 30px;
    padding-right: 10px;
}

.edit {
    font-size: 16px;
}

.table1 {
    margin-left: 550px;
}

.dropDownBtn {
    height: 32px;
}

.menuIcon {
    margin-left: 20px;
}

.currency {
    font-size: 24px;
    padding-left: 7px;
}

.majorContainer {
    display: flex;
    flex-direction: row;
}

.container1 {
    background-color: maroon;
    color: white;
    border-radius: 14px;
    align-items: center;
    justify-content: center;
    width: 222px;
}

.amtTxt {
    color: rgb(255, 255, 255, 0.8);
    padding-top: 4px;

}

.iconBg {
    background-color: #2891ce;
    margin-top: 14px;
    border-radius: 5px;
    height: 30px;
    width: 40px;
}

.rupeeIcon {
    padding-top: 2px;
    font-size: 27px;
}

.boxContainer {
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 600;
    color: white;
}

.paid-container {
    background-color: rgb(0 227 150);
    color: #fff;
}

.iconBg2 {
    background-color: #33CC99 !important;
}

.dollarIcon {
    font-size: 20px;
    margin-right: 3px;
}

.job-container {
    background-color:rgb(255 111 111); 
    color: #fff;
}

.amount-container {
    background-color: #2d9aed;
    color: #fff;
}

.amountBoxContainer {
    padding: 20px;
}

.due-container {
    background-color: #ebbc4b;
    color: #fff;
}

.iconBg3 {
    background-color: #c48628;
}


.tableDataCell {
    padding: 16px;
}

.btnJobs {
    background-color: #00bcd4;
    color: white;
    margin-top: 20px;
    padding: 10px 24px 10px 24px;
    border: #4c5667;
    border-radius: 5px;
}

.unpaid {
    background-color: #d9534f;
    padding: 10px;
    width: 120px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.txtUnpaid {
    text-align: center;
    color: white;
}

.span111 {
    font-weight: 400;
    font-size: 20px;
}

.secondCard {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}

.thirdCard {
    padding-top: 10px !important;
    padding-bottom: 20px !important;
}

/* .totalCard{
    width: 400px;
} */

.hoverDiv {
    color: white;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 2s ease !important;
}

.hoverDiv:hover {
    transform: scale(1.1);
}

.dropDownBtn {
    height: 36px;
}