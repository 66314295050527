.lead_dropdown{
    line-height: 20px;
}
.b-left-3-o{
    border-left: 3px solid orange;
}
.b-left-3-g{
    border-left: 3px solid #3db583;
}

.round-button {
    display: inline-block;
    border: none;
    border-radius: 50%;
    padding: 4px 10px;
    text-align: center;
    text-decoration: none;
    background-color: #065f53;
    color: white;
    cursor: pointer;
  }
  
  .round-button:hover {
    background-color: #34b1a0;
  }
  

  .round-buttonbtn {
    display: inline-block;
    border: none;
    border-radius: 50%;
    padding: 4px 10px;
    text-align: center;
    text-decoration: none;
    background-color: #09a08c;
    color: white;
    cursor: pointer;
  }
  
  .round-button:hover {
    background-color: #34b1a0;
  }

  .round-buttonbtn:hover {
    background-color: #34b1a0;
  }

  .green-hr {
  border-color: #0016a8;
  border-width: 3px;
  margin: 10px 0;
}

.light-grey-background {
    background-color: #f0f0f0; /* Replace with the desired light grey color */
  }
  

  
  .question-list-container {
    height: 200px; /* Adjust the height as needed */
    overflow: auto;
    border: 1px solid #ccc;
  }
  
  .question-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .question-list li {
    cursor: pointer;
    padding: 8px;
    border-bottom: 1px solid #ccc;
  }
  
  .question-list li:last-child {
    border-bottom: none;
  }
  
  .question-list li.selected {
    background-color:#912a4e;
    // text-decoration: underline;
    color: #f0f0f0;
  }
  