.tags-section {
    .tags {
        display: inline-block;
        background: #912a4e;
        color: white;
        padding: 3px 10px 3px 10px;
        border-radius: 15px;
        margin-right: 6px;
        margin-bottom: 0px;
        margin-top: 10px;
    }

    i {
        vertical-align: bottom;
    }
}

.event_border_left_unpaid {
    border-left: 1rem solid #FF0000 !important;
}

.date-color {
    color: #d9424d;
    float: right;
}

.edit-icon {
    float: right;
    width: fit-content;

    .show_update_job_detail_btn:hover {
        box-shadow: 0 0 0 62.5rem rgba(0, 0, 0, .075) inset;
    }
}

.pd-field {
    padding: 5px 0px;
}

.btn-light {
    background-color: #f5f5f5;
    border-color: #ddd;
}

.collapse-section {
    padding: 5px;
    background-color: #eef7fb;
}

.header-elements-inline {
    //display:flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-bottom: 0px;
    border: 1px solid #d2d2d2;

    .txt-white {
        color: #fff;
    }

    .page-title {
        padding: 8px 0 8px 5px;
        position: relative;
        width: 100%;
    }

    .align-center {
        align-items: center;
    }

    .d-flex {
        display: flex;
    }

    span {
        margin-left: 10px;
    }
}

.align-inherit {
    vertical-align: inherit !important;
}

.btn:disabled {
    color: #5E5E5E;
    border: solid 1px #f7f8f9;
}

.contact-sec-top {
    padding: 5px 16px;
}

.contact-sec-bottom {
    padding: 5px 16px;
    background-color: #eef7fb;
}

.edit_row {
    background-color: #EFF7FB
}

.pac-container {
    z-index: 9999 !important;
}

.current_opportunity {
    border-left: 4px solid #03a9f4;
    background-color: #eef7fb;
}

.top_search_list {
    position: absolute;
    left: 2.8rem;
}

.search-list-dropdown {
    width: 251px;
    max-height: 300px;
    overflow-y: scroll;
    top: 2rem;
}

.brown-button {
    background-color: #912a4e;
    color: white;
    border: none;
    padding: 5px;
    cursor: pointer;
}

.from-label {
    color: blue;
    /* Add any other styles you want for the "From" label */
}

.close-btn {
    background-color: #f44336;
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
}

.StripeElement {
    background-color: white;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
    border-color: #fa755a;
}

.payment-form {
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.close-icon {
    position: absolute;
    top: 8px;
    right: 10px;
    font-size: 12px;
    cursor: pointer;
    padding: 5px;
    border-radius: 50%;
    background-color: #f44336;
    color: #fff;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.stripe-title-1 {
    text-align: center;
    margin: 15px 0px 25px 0px;
}

.balance-input {
    margin: 15px 0px 20px 0px;
}

.stripe_email {
    margin-bottom: 10px;
    width: 100%;
    padding: 5px;
}

.scriptdiv {
    padding: 10px;
    border: 1px solid #78828c21;
    border-left: 5px solid #ff6849;

    font-size: 12px;
}

//Full Calendar
.fc-scrollgrid-section-header {
    background-color: #912a4e;
    color: white;
    /* Optional: Change text color to white for better visibility */
}

.fc-timeline-header {
    color: #fff;
}


.fc-resource-group {
    background-color: #171e27;
}

.fc .fc-timeline-slot-minor {
    border-style: none !important;
}

.fc .fc-timeline-slot-cushion {
    color: white;
}

.static-unpaid {
    border-left: 1rem solid #FF0000 !important;
    padding: 0 1.3rem 0 5px;
}

.static-partial {
    border-left: 1rem solid #FF7F50 !important;
    padding: 0 1.3rem 0 5px;
}

.static-paid {
    border-left: 1rem solid #008000 !important;
    padding: 0 1.3rem 0 5px;
}

.static_complete {
    border-left: 1rem solid #000000 !important;
    padding: 0 1.3rem 0 5px;
}

.eventTooltip {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    z-index: 1000;
    /* Position the tooltip near the event */
    left: 0;
    top: 50%;
    /* Adjust this value to position the tooltip correctly */
}
.header-profile-user{
    width: 60px;

}
.record_count{
    margin-left:10px;
}