* {
  font-family: "Rubik", sans-serif !important;
}

a,
a:hover {
  text-decoration: none;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
label {
  margin-bottom: 0;
}

.header-area {
  background: #ffffff;
  box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.25);
  padding: 12px 0 12px 0;
}

.header-item1 ul {
  display: flex;
  align-items: center;
}

.header-item1 ul li {
  margin-right: 18px;
}

.header-item1 ul li:last-child {
  margin-right: 0;
}

.header-item1 ul li:nth-of-type(1) a,
.header-item1 ul li:nth-of-type(2) a {
  width: 43px;
  height: 43px;
  border-radius: 50%;
  background: #912a4e2e;
  display: block;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transition: 0.2s all ease;
}

.header-item1 ul li:nth-of-type(1) a:hover,
.header-item1 ul li:nth-of-type(2) a:hover {
  opacity: 0.8;
}

.header-item1 ul li span {
  font-weight: 500;
  font-size: 20px;
  color: #000000;
}

.header-item2 h4 {
  font-weight: 700;
  font-size: 24px;
  color: #000000;
}


.header-item2 h4 span:nth-of-type(2) img {
  max-width: 28px;
  margin-left: 18px;
}

.header-item3 {
  text-align: right;
}

.header-item3 {
  border-radius: 10px;
  border: 1px solid #EFF2F4;
  padding-right: 16px;
  max-width: 232px;
  margin-left: auto;
}

.header-item3 ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header-item3 ul li {
  margin-right: 16px;
}

.header-item3 ul li:last-child {
  margin-right: 0;
}

.header-item3 ul li a {
  font-weight: 400;
  font-size: 14px;
  color: #98A8B7;
}

.header-item3 .active a {
  background: #912a4e;
  padding: 6px 16px;
  box-shadow: 0px 1px 3px rgba(246, 66, 150, 0.3), 0px 4px 8px rgba(234, 79, 174, 0.234);
  display: block;
  border-radius: 10px;
  color: #ffffff !important;
}

/* table-box-start */
.table-area {
  padding: 29px 0 30px 0;
}

.table-header {

  border-radius: 10px 10px 0 0;
}

.table-header-lft {
  padding: 10px 25px 10px;
  // border-right: 1px solid #98A8B7;
}

.table-header-lft h5 {
  font-weight: 500;
  text-transform: uppercase;
  color: #FFFFFF;
}

.table-subheader-lft {
  padding: 11px 10px 11px 16px;
}

.table-subheader-lft h6 {
  font-weight: 500;
  text-transform: uppercase;
  color: #ffffff;
}

.table-subheader-lft h6 span {
  margin-right: 12px;
}

.table-subheader {
  background: #11171D;
}

.table-item-wrap {
  border: 1px solid #98A8B7;
}


.border-inner {
  border-top: 0;
}

.table-item-left {
  // border-right: 1px solid #98A8B7;
  padding: 13px 5px 13px 16px;
}

.right-border {
  border-right: 1px solid #98A8B7;
}

.table-item-left h6 {
  font-weight: 500;
  color: #000000;
}

.table-item-left h6 span img {
  max-width: 41px;
  margin-right: 18px;
}

.table-item-wrap1 {
  background: #11171D;
  padding: 11px 10px 11px 16px;
  border-right: 0;
}

.table-item-wrap1 h6 {
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  color: #FFFFFF;
}

.table-item-left1 {
  border-right: 0;
}

.table-item-wrap2 {
  border-radius: 0 0 10px 10px;
}

.trip-type-box-wrap {
  min-width: 490px;
  background: #912a4e;
  border-radius: 51px;

  margin: auto;
  position: relative;

}

.flatpickr {
  text-align: center;
  padding: 5px 5px;
  font-size: 10px;
  border-radius: 15px;
  border: 1px solid #912a4e;
  cursor: pointer;
  height: auto !important;
  margin: 0px !important;
}

.trip-type-box-wrap2 {
  margin-left: 353px;
}

.trip-type-box-wrap3 {
  margin-left: 83px;
}

.trip-type-cnt-left::after {
  content: "";
  display: block;
  width: 1px;
  height: 100%;
  position: absolute;
  background: #912a4e;
  top: 0;
  left: 95px;
}

.trip-inner {
  position: absolute;
  top: 49%;
  right: 15px;
  transform: translateY(-49%);
}

.trip-inner span {
  font-weight: 700;
  font-size: 12px;
  color: #ffffff;
}

.trip-type-cnt {
  background: #912a4e2e;
  width: 90%;
  height: 100%;
  border-radius: 51px;
  margin-left: -1px;
  padding: 8px 14px 8px 22px;
}

.trip-type-cnt {
  display: flex;
  // background-color: #d9d2e9;
  align-items: center;
}

.trip-type-cnt-left {
  width: 20%;
}

.trip-type-cnt-left p {
  font-weight: 700;
  font-size: 12px;
  color: #912a4e;
}

.trip-type-cnt-right {
  width: 80%;
}

.trip-type-cnt-right ul {
  display: flex;
  align-items: center;
  justify-content: center;
}

.trip-type-cnt-right ul li {
  margin-right: 20px;
  font-weight: 500;
  font-size: 14px;
  color: #912a4e;
  background: #ffffff;
  border-radius: 37px;
  padding: 3px 15px;

}

.trip-type-cnt-right ul li:last-child {
  margin-right: 0;
}

/* table-box-end */

.dropdown-hover {
  position: absolute;
  z-index: 10001;
  width: 280px;
  margin-left: 20px;
  border-radius: 0px;
}

.hover-drop-content {
  border: 1px solid #cacaca;
}

.bottom-border {
  border-bottom: 1px solid gray;
}